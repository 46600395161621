import * as React from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { hasValue } from '@digital-magic/ts-common-utils'
import { VehicleId } from '@api/endpoints'
import { getVehicleOptionsById, VehicleOptionResponse } from '@api/endpoints/vehicles'
import { useDefaultQueryErrorHandlerPublic } from '@hooks/ReactQuery'
import { queryKeyGetVehicleOptionsById } from './queryKeys'

export const useQueryGetVehicleOptionsById = (
  vehicleId: VehicleId
): UseQueryResult<ReadonlyArray<VehicleOptionResponse>> => {
  const errorHandler = useDefaultQueryErrorHandlerPublic()

  const query = useQuery({
    queryKey: queryKeyGetVehicleOptionsById(vehicleId),
    queryFn: () => getVehicleOptionsById(vehicleId),
    enabled: hasValue(vehicleId)
  })

  React.useEffect(() => {
    if (query.error) {
      errorHandler(query.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.error])

  return query
}
