export const hasNext =
  <T>(items: ReadonlyArray<T>) =>
  (index: number): boolean =>
    index < items.length - 1

export const hasPrev =
  <T>(items: ReadonlyArray<T>) =>
  (index: number): boolean =>
    items.length > 0 && index > 0

export const increment =
  <T>(items: ReadonlyArray<T>) =>
  (index: number): number =>
    hasNext(items)(index) ? index + 1 : index

export const decrement =
  <T>(items: ReadonlyArray<T>) =>
  (index: number): number =>
    hasPrev(items)(index) ? index - 1 : index
