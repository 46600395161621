import * as React from 'react'
import clsx from 'clsx'
import { Link, LinkProps } from '@mui/material'
import styles from './CircleLink.module.css'

export type CircleLinkProps = LinkProps

export const CircleLink: React.FC<CircleLinkProps> = ({ className, ...props }) => (
  <Link className={clsx(styles.circleLink, className)} {...props} />
)
