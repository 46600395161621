import * as React from 'react'
import { useParams } from 'react-router-dom'
import { VehicleId } from '@api/endpoints'
import { parseVehicleId } from './utils'

export type ParamsVehicleId = Readonly<{
  vehicleId: string
}>

export const useParamsVehicleId = (): VehicleId | undefined => {
  const { vehicleId } = useParams<ParamsVehicleId>()

  return React.useMemo(() => parseVehicleId(vehicleId), [vehicleId])
}
