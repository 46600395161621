import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { VehicleFull } from '@api/endpoints/vehicles/types'
import { formatNumberInEstonian } from '@model/language'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import {
  useFindFuelType,
  useFindTransmissionType,
  useFindVehicleColor,
  useFindVehicleVariant,
  useFindWheelDrive
} from '@hooks/Translation/TranslatedEntity'
import { useTranslateOptionalValue } from '@hooks/Translation/TranslatedValue'
import { FlexContainer } from '@layout/FlexContainer'
import { anyNumericValueGreaterThanZero } from '@pages/utils'
import vehiclePageStyles from '../VehiclePage.module.css'
import styles from './VehicleBasicInfo.module.css'

type Props = {
  vehicle: VehicleFull
}

export const VehicleBasicInfo: React.FC<Props> = ({ vehicle }) => {
  const { t } = useTranslation()

  const translateOptionalValue = useTranslateOptionalValue()
  const vehicleVariant = translateOptionalValue(useFindVehicleVariant(vehicle.mainData.variant?.value))
  const transmissionType = translateOptionalValue(useFindTransmissionType(vehicle.mainData.transmission?.value))
  const wheeDrive = translateOptionalValue(useFindWheelDrive(vehicle.mainData.wheelDrive?.value))
  const fuelType = translateOptionalValue(useFindFuelType(vehicle.mainData.fuel?.value))
  const vehicleColor = translateOptionalValue(useFindVehicleColor(vehicle.mainData.color?.value))

  return (
    <FlexContainer vertical>
      <h3 className={vehiclePageStyles.subtitle}>{t('pages.vehicle.basic_characteristics_title')}</h3>
      <Table className={styles.vehicleBasicInfoContainer}>
        <TableBody>
          <TableRow>
            <TableCell>{t('components.cards.vehicle.headers.body-type')}</TableCell>
            <TableCell>{vehicleVariant}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('components.cards.vehicle.headers.year')}</TableCell>
            <TableCell>{vehicle.mainData.year}</TableCell>
          </TableRow>

          {vehicle.mainData.mileage && (
            <TableRow>
              <TableCell>{t('components.cards.vehicle.headers.mileage')}</TableCell>
              <TableCell>
                {t('components.cards.vehicle.odometer-reading', {
                  odometerReading: formatNumberInEstonian(vehicle.mainData.mileage)
                })}
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>{t('components.cards.vehicle.headers.gearbox-type')}</TableCell>
            <TableCell>{transmissionType}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('components.cards.vehicle.headers.transmission-type')}</TableCell>
            <TableCell>{wheeDrive}</TableCell>
          </TableRow>

          {fuelType && (
            <TableRow>
              <TableCell>{t('components.cards.vehicle.headers.engine')}</TableCell>
              <TableCell>
                {`${t('components.cards.vehicle.engine-capacity', {
                  engineCapacity: vehicle.mainData.engineDisplacement
                })} / ${fuelType} / ${t('components.cards.vehicle.engine-power', {
                  enginePower: vehicle.mainData.powerInKw
                })} `}
              </TableCell>
            </TableRow>
          )}

          <TableRow>
            <TableCell>{t('components.cards.vehicle.headers.color')}</TableCell>
            <TableCell>{vehicleColor}</TableCell>
          </TableRow>

          {vehicle.mainData.registrationNumber && (
            <TableRow>
              <TableCell>{t('components.cards.vehicle.headers.reg-number')}</TableCell>
              <TableCell>{vehicle.mainData.registrationNumber}</TableCell>
            </TableRow>
          )}
          {anyNumericValueGreaterThanZero(vehicle.mainData.fuelConsumptionAverage) && (
            <TableRow>
              <TableCell>
                {t('components.cards.vehicle.headers.fuel-consumption')} (
                {t('components.cards.vehicle.fuel-consumption-types.average')})
              </TableCell>
              <TableCell>
                <div>
                  {t('components.cards.vehicle.fuel-consumption', {
                    fuelConsumption: vehicle.mainData.fuelConsumptionAverage
                  })}
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </FlexContainer>
  )
}
