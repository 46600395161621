import * as React from 'react'
import vehiclePageStyles from '@pages/VehiclePage/VehiclePage.module.css'
import { hasValue } from '@digital-magic/ts-common-utils'
import { FlexContainer, FlexContainerProps } from '@layout/FlexContainer'

export type Props = React.PropsWithChildren &
  FlexContainerProps &
  Readonly<{
    title?: React.ReactNode
    className?: string
  }>

export const VehicleActualInfo: React.FC<Props> = ({ title, children, className, ...props }) => {
  return (
    <FlexContainer fitHeight={props.fitHeight} vertical bordered noBorderSm className={className}>
      {hasValue(title) && <h3 className={vehiclePageStyles.subtitle}>{title}</h3>}
      {children}
    </FlexContainer>
  )
}
