import { Handler } from '@digital-magic/ts-common-utils'
import { UrlString } from '@api/endpoints'
import { VehicleFull, VehicleImage } from '@api/endpoints/vehicles'
import { MediaSliderProps } from '@controls/MediaSlider'

export const buildImages = (
  images: ReadonlyArray<VehicleImage>,
  onClick: Handler<UrlString>
): MediaSliderProps['images'] =>
  images.map((image, i) => ({
    //url: image.imageUrl,
    srcSm: image.thumbnailUrl,
    srcMd: image.thumbnailUrl,
    srcLg: image.thumbnailUrl,
    srcXl: image.imageUrl,
    src: image.thumbnailUrl,
    alt: `Photo #${i}`,
    loading: 'lazy',
    onClick: () => onClick(image.imageUrl)
  }))

export const buildSliderImages = (
  vehicle: VehicleFull | undefined,
  onClickImage: Handler<UrlString>,
  limit?: number
): MediaSliderProps['images'] =>
  vehicle ? buildImages(limit ? vehicle.attachments.slice(0, limit) : vehicle.attachments, onClickImage) : []
