import * as React from 'react'
import { useTheme } from '@hooks/useTheme'
import { useMediaQuery } from '@mui/material'
import Modal from '@mui/material/Modal'
import { CommonModalProps } from '@controls/types'
import { ButtonClose } from '@controls/buttons'
import { MediaSlider, MediaSliderProps } from '../MediaSlider'
import styles from './ModalMediaSlider.module.css'
import { FlexContainer } from '@layout/FlexContainer'

export type ModalMediaSliderProps = Omit<MediaSliderProps, 'slidesToShow'> & CommonModalProps

export const ModalMediaSlider: React.FC<ModalMediaSliderProps> = ({ open, onClose, ...props }) => {
  const { currentTheme } = useTheme()
  const isDownMd = useMediaQuery(currentTheme.breakpoints.down('md'))

  return (
    <Modal
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.95)'
        }
      }}
      className={'mediaModal'}
      open={open}
      // eslint-disable-next-line functional/prefer-tacit
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        {/* TODO: without this wrapping, there's a warning:  Invalid prop `children` supplied to `ForwardRef(Modal2)`, looks like a bug of new MUI version:  */}
        <FlexContainer fixedDirection justifyContentCenter className={styles.modalMediaSliderContainer}>
          <ButtonClose onClick={onClose} />
          <MediaSlider className={styles.mediaSlider} slidesToShow={isDownMd ? 2 : 4} {...props} />
        </FlexContainer>
      </>
    </Modal>
  )
}
