import * as React from 'react'
import SvgIcon360 from '@assets/icons/360-icon.svg'
import { CircleLink, CircleLinkProps } from './CircleLink'
import styles from './CircleLink360.module.css'

export const CircleLink360: React.FC<Omit<CircleLinkProps, 'children'>> = (props) => (
  <CircleLink {...props}>
    <img src={SvgIcon360} alt="360 view" className={styles.icon360} />
  </CircleLink>
)
