import * as React from 'react'
import clsx from 'clsx'
import { VehicleFull } from '@api/endpoints/vehicles/types'
import { useMediaQuery, useTheme } from '@mui/material'
import { VehicleMonthlyPayment, CarprofCertified, VehiclePrice } from '@controls/panels'
import { FlexContainer } from '@layout/FlexContainer'
import styles from './VehicleInfoLabels.module.css'

type Props = {
  vehicle: VehicleFull
}

export const VehicleInfoLabels: React.FC<Props> = ({ vehicle }) => {
  const theme = useTheme()
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'))

  const monthlyPaymentVehicleId = vehicle.isAvailable ? vehicle.id : undefined

  return (
    <FlexContainer vertical className={clsx(styles.vehicleInfoLabelsContainer)}>
      {isDownSm ? (
        <>
          <VehicleMonthlyPayment vehicleId={monthlyPaymentVehicleId} amount={vehicle.monthlyPaymentFrom} />
          <FlexContainer fixedDirection justifyContentSpaceBetween responsiveGap>
            <CarprofCertified />
            <div className={styles.separator} />
            <VehiclePrice vehicle={vehicle} align="flex-end" />
          </FlexContainer>
        </>
      ) : (
        <FlexContainer responsiveGap fixedDirection justifyContentCenter>
          <CarprofCertified />
          <VehicleMonthlyPayment vehicleId={monthlyPaymentVehicleId} amount={vehicle.monthlyPaymentFrom} fullWidth />
          <div className={styles.separator} />
          <VehiclePrice vehicle={vehicle} align="flex-end" />
        </FlexContainer>
      )}
    </FlexContainer>
  )
}
