import * as React from 'react'
import { CircleLink, CircleLinkProps } from './CircleLink'
import SvgIconYoutube from '@assets/icons/youtube-icon.svg'
import styles from './CircleLinkYoutube.module.css'

export const CircleLinkYoutube: React.FC<Omit<CircleLinkProps, 'children'>> = (props) => (
  <CircleLink {...props}>
    <img src={SvgIconYoutube} alt="youtube video" className={styles.iconYoutube} />
  </CircleLink>
)
