import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { VehicleId, VinCode } from '@api/endpoints'
import { useLanguage } from '@hooks/useLanguage'
import { LinkCarVertical, RouterLink } from '@controls/buttons'
import { FlexContainer } from '@layout/FlexContainer'
import { urlWithQueryParamVehicleId } from '@pages/utils'
import styles from './VehicleLinksToActualInfo.module.css'

type Props = Readonly<{
  vehicleId?: VehicleId
  vin?: VinCode
}>

export const VehicleLinksToActualInfo: React.FC<Props> = ({ vehicleId, vin }) => {
  const { t } = useTranslation()
  const { routes } = useLanguage()

  const createLink = (route: string): string => (vehicleId ? urlWithQueryParamVehicleId(route, vehicleId) : route)

  return (
    <FlexContainer vertical fullHeight justifyContentSpaceBetween>
      <FlexContainer wrap responsiveGap>
        <RouterLink className={styles.link} to={createLink(routes.Installment)}>
          <span className={styles.text}>{t('pages.vehicle.services.installment.text')}</span>
        </RouterLink>
        <RouterLink className={styles.link} to={createLink(routes.Exchange)}>
          <span className={styles.text}>{t('pages.vehicle.services.exchange.text')}</span>
        </RouterLink>
        <RouterLink className={styles.link} to={routes.Guarantee}>
          <span className={styles.text}>{t('pages.vehicle.services.guarantee.text')}</span>
        </RouterLink>
        <RouterLink className={styles.link} to={routes.Guarantee}>
          <span className={styles.text}>{t('pages.vehicle.services.mileage.text')}</span>
        </RouterLink>
        <RouterLink className={styles.link} to={routes.Delivery}>
          <span className={styles.text}>{t('pages.vehicle.services.delivery.text')}</span>
        </RouterLink>
      </FlexContainer>
      <LinkCarVertical vin={vin} />
    </FlexContainer>
  )
}
