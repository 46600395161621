import * as React from 'react'
import { DialogContent } from '@mui/material'
import { CarprofModalDialog, CarprofModalDialogProps } from '@controls/dialogs/CarprofModalDialog'
import styles from './ModalSuccess.module.css'
import { ButtonClose } from '@controls/buttons'

type Props = CarprofModalDialogProps &
  Readonly<{
    title: string
  }>

export const ModalSuccess: React.FC<Props> = ({ title, ...props }) => {
  return (
    <CarprofModalDialog {...props}>
      <div className={styles.modalSuccessContainer}>
        <DialogContent>
          {title}
          <ButtonClose onClick={() => props?.onClose()} color="secondary" />
        </DialogContent>
      </div>
    </CarprofModalDialog>
  )
}
