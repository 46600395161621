import * as React from 'react'
import { Dialog } from '@mui/material'
import { DialogProps } from '@mui/material/Dialog'
import { ThemeProvider } from '@mui/material/styles'
import { commonColors } from '@styles/theme/colors'
import { lightTheme } from '@styles/theme/lightTheme'
import { ClassNameProps, CommonModalProps } from '@controls/types'
import styles from './DialogContent.module.css'
import clsx from 'clsx'

export type CarprofModalDialogProps = Omit<DialogProps, 'onClose'> & CommonModalProps & ClassNameProps

export const CarprofModalDialog: React.FC<CarprofModalDialogProps> = ({ children, className, ...props }) => {
  return (
    <Dialog
      className={clsx(styles.dialogContainer, className)}
      {...props}
      PaperProps={{
        ...props.PaperProps,
        sx: { ...props.PaperProps?.sx, margin: 0, backgroundColor: commonColors.white, overflowX: 'hidden' }
      }}
      maxWidth={false}
      BackdropProps={{sx: {backgroundColor: 'rgba(0, 0, 0, 0.8)'}}}
    >
      {/*TODO: Consider another solution instead of hard-coding certain theme */}
      <ThemeProvider theme={lightTheme}>{children}</ThemeProvider>
    </Dialog>
  )
}
