import 'slick-carousel/slick/slick.css'
import * as React from 'react'
import Slider from 'react-slick'
import clsx from 'clsx'
import { Handler } from '@digital-magic/ts-common-utils'
import vehicleDefaultImage from '@assets/images/vehicleDefaultImage.png'
import iconArrow from '@assets/icons/sliderArrow.svg'
import { Button, Link } from '@mui/material'
import { ImgResponsive, ImgResponsiveProps } from '@controls/ImgResponsive'
import { FlexContainer } from '@layout/FlexContainer'
import { hasNext, hasPrev } from './utils'
import styles from './MediaSlider.module.css'

export type MediaSliderProps = Readonly<{
  images: ReadonlyArray<Omit<ImgResponsiveProps, 'onClick'> & { onClick: () => void }>
  youtubeVideos?: ReadonlyArray<Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'onClick'>>
  currentSlide: number
  slidesToShow: number
  setCurrentSlide: Handler<number>
  hideArrows?: boolean
  className?: string
  disabled?: boolean
}>

export const MediaSlider: React.FC<MediaSliderProps> = ({
  images,
  currentSlide,
  slidesToShow,
  setCurrentSlide,
  hideArrows,
  className,
  disabled
}) => {
  const [refUpper, setRefUpper] = React.useState<Slider | null>(null)
  const [refLower, setRefLower] = React.useState<Slider | null>(null)
  //const [current, setCurrent] = React.useState(currentSlide)

  React.useEffect(() => {
    if (refLower) {
      // Dirty hack to make current slide to be focused
      refLower.innerSlider?.list?.setAttribute('tabindex', '0')
      refLower.innerSlider?.list?.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refLower])

  React.useEffect(() => {
    if (refUpper) {
      refUpper.slickGoTo(currentSlide)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refUpper])

  const handleNextClick = (): void => {
    if (refUpper) {
      refUpper.slickNext()
    }
  }

  const handlePrevClick = (): void => {
    if (refUpper) {
      refUpper.slickPrev()
    }
  }

  const handleBeforeChange = (currentSlide: number, nextSlide: number): void => {
    if (currentSlide !== nextSlide) {
      setCurrentSlide(nextSlide)
    }
  }

  /*
  const handleAfterChange = (currentSlide: number): void => {
    console.log('handleAfterChange', currentSlide)
  }
  */

  return (
    <FlexContainer vertical noGap alignItemsCenter className={className}>
      <div className={clsx(styles.sliderWrapper, 'mediaSlider-sliderWrapper')}>
        <Slider
          className={clsx(styles.topSlider, 'mediaSlider-topCarousel')}
          arrows={false}
          asNavFor={refLower ?? undefined}
          ref={setRefUpper}
          //initialSlide={currentSlide}
          infinite={false}
          accessibility={false}
          speed={400}
          //waitForAnimate={true}
          //accessibility={true}
          // autoplay={true}
          //speed={1000}
          //autoplaySpeed={4000}
          beforeChange={handleBeforeChange}
          //afterChange={handleAfterChange}
        >
          {images.length > 0
            ? images.map(({ onClick, className, ...props }, index) => (
                <Link key={index} onClick={disabled ? undefined : onClick}>
                  {/* need to put an extra class 'upperImg' to reach it from the module slider styles and change the cursor on upper img */}
                  <ImgResponsive
                    className={clsx(styles.topSliderImage, 'mediaSlider-topImage', className)}
                    {...props}
                  />
                </Link>
              ))
            : [
                <img
                  key="default image upper"
                  className={clsx(styles.topSliderImage, styles.defaultImage)}
                  src={vehicleDefaultImage}
                  alt="default image"
                />
              ]}
        </Slider>
      </div>
      <FlexContainer fixedDirection alignItemsCenter justifyContentCenter noGap>
        {hideArrows !== true && (
          <div className={styles.arrowContainer}>
            {!disabled && hasPrev(images)(currentSlide) && (
              <Button className={styles.slickArrowBtnPrev} disabled={disabled} onClick={handlePrevClick}>
                <img src={iconArrow} alt="previous slide" className={styles.slickArrowImg} />
              </Button>
            )}
          </div>
        )}

        <div className={clsx(styles.sliderWrapper, 'mediaSlider-sliderWrapper')}>
          <Slider
            centerPadding={'0px'}
            className={clsx(styles.bottomSlider, 'mediaSlider-bottomCarousel')}
            arrows={false}
            asNavFor={refUpper ?? undefined}
            ref={setRefLower}
            swipeToSlide={true}
            focusOnSelect={true}
            initialSlide={currentSlide}
            slidesToShow={slidesToShow}
            infinite={false}
            waitForAnimate={true}
            accessibility={true}
            autoplay={true}
            speed={1000}
            autoplaySpeed={4000}
          >
            {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
            {images.map(({ onClick, className, ...props }, index) => (
              <ImgResponsive
                key={index}
                className={clsx(styles.bottomSliderImage, 'mediaSlider-bottomImage', className)}
                {...props}
              />
            ))}
          </Slider>
        </div>
        {hideArrows !== true && (
          <div className={styles.arrowContainer}>
            {!disabled && hasNext(images)(currentSlide) && (
              <Button className={styles.slickArrowBtnNext} disabled={disabled} onClick={handleNextClick}>
                <img
                  src={iconArrow}
                  alt="next slide"
                  className={clsx(styles.slickArrowImg, styles.slickArrowImgNext)}
                />
              </Button>
            )}
          </div>
        )}
      </FlexContainer>
    </FlexContainer>
  )
}
