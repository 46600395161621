import { UrlString } from '@api/endpoints'
import { ImgResponsiveProps } from '@controls/ImgResponsive'

export enum ModalType {
  Success,
  Booking,
  CallRequest,
  Notification,
  Image,
  Image360,
  Video
}

export type MediaContent = ImgResponsiveProps &
  Readonly<{
    url?: UrlString
  }>
