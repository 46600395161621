import * as React from 'react'
import clsx from 'clsx'
import { WarrantyTypeEnum } from '@api/endpoints/vehicles'
import ImgCheckRound from '@assets/icons/check-round.svg'
import { useLanguage } from '@hooks/useLanguage'
import { useEnumTranslation } from '@hooks/Translation/EnumTranslation'
import { RouterLink } from '@controls/buttons'
import { FlexContainer } from '@layout/FlexContainer'

import styles from './LinkWarranty.module.css'

type Props = {
  warrantyType: WarrantyTypeEnum
}

export const LinkWarranty: React.FC<Props> = ({ warrantyType }) => {
  const { routes } = useLanguage()
  const { warrantyTypeTranslation } = useEnumTranslation()

  return (
    <FlexContainer wrap alignItemsCenter responsiveGap className={clsx(styles.guaranteeLinkContainer, 'guaranteeLink')}>
      <img className={styles.guaranteeIcon} src={ImgCheckRound} alt="guarantee-icon" />
      <RouterLink to={routes.Guarantee}>{warrantyTypeTranslation(warrantyType)}</RouterLink>
    </FlexContainer>
  )
}
