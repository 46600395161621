import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { VehicleFull } from '@api/endpoints/vehicles'
import { Button } from '@mui/material'
import { useLanguage } from '@hooks/useLanguage'
import { BreadCrumbsProps } from '@controls/BreadCrumbs'
import { initialCatalogFiltrationForm, useCatalogParamsStore } from '@forms/Catalog'

export const useVehiclePageBreadcrumbs = (vehicle: VehicleFull | undefined): BreadCrumbsProps['breadCrumbs'] => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { routes } = useLanguage()

  const setCatalogFiltrationForm = useCatalogParamsStore((s) => s.setCatalogFiltrationForm)

  const handleMakeLinkClick = React.useCallback(() => {
    if (vehicle) {
      setCatalogFiltrationForm({ ...initialCatalogFiltrationForm, make: vehicle.make })
      navigate(routes.Catalog)
    }
  }, [routes, navigate, setCatalogFiltrationForm, vehicle])

  const handleModelLinkClick = React.useCallback(() => {
    if (vehicle) {
      setCatalogFiltrationForm({ ...initialCatalogFiltrationForm, make: vehicle.make, model: vehicle.model })
      navigate(routes.Catalog)
    }
  }, [routes, navigate, setCatalogFiltrationForm, vehicle])

  return React.useMemo(
    () =>
      vehicle
        ? [
            { to: routes.Index, title: t('pages.main.title') },
            { to: routes.Catalog, title: t('pages.catalog.title') },
            {
              title: (
                <Button variant="text" color="inherit" onClick={handleMakeLinkClick}>
                  {vehicle.make}
                </Button>
              )
            },
            {
              title: (
                <Button variant="text" color="inherit" onClick={handleModelLinkClick}>
                  {vehicle.model}
                </Button>
              )
            }
          ]
        : [
            { to: routes.Index, title: t('pages.main.title') },
            { to: routes.Catalog, title: t('pages.catalog.title') }
          ],
    [t, routes, handleMakeLinkClick, handleModelLinkClick, vehicle]
  )
}
