import * as React from 'react'
import { hasValue, isEmpty } from '@digital-magic/ts-common-utils'
import { UrlString, VehicleId } from '@api/endpoints'
import { useLanguage } from '@hooks/useLanguage'
import { VehicleSelectionMode } from './types'
import { isVehicleSelectionMode, urlBySelectionMode } from './utils'
import { useSearchParamsSelectionMode } from './useSearchParamsSelectionMode'
import { useParamsVehicleId } from './useParamsVehicleId'

export type UseRetrieveSelectionModeRelatedProps = Readonly<{
  selectionMode: VehicleSelectionMode | undefined
  vehicleId: VehicleId | undefined
  returnUrl: UrlString | undefined
}>

export const useRetrieveSelectionModeRelatedProps = (): UseRetrieveSelectionModeRelatedProps => {
  const selectionMode = useSearchParamsSelectionMode()
  const vehicleId = useParamsVehicleId()
  const { language } = useLanguage()

  return React.useMemo(() => {
    if (hasValue(selectionMode) && isEmpty(vehicleId)) {
      // eslint-disable-next-line no-console
      console.error('vehicleId must not be empty if selectionMode is set')
    }
    return {
      selectionMode,
      vehicleId,
      returnUrl:
        hasValue(vehicleId) && hasValue(selectionMode) && isVehicleSelectionMode(selectionMode)
          ? urlBySelectionMode(selectionMode, vehicleId, language)
          : undefined
    }
  }, [selectionMode, vehicleId, language])
}
