import * as React from 'react'
import clsx from 'clsx'
import vehicleDefaultImage from '@assets/images/vehicleDefaultImage.png'
import { UrlString } from '@api/endpoints'
import { pannellum } from '@controls/Panorama'
import styles from './Panorama.module.css'

export type PanoramaProps = Readonly<{
  url: UrlString
  className?: HTMLDivElement['className']
}>

export const Panorama: React.FC<PanoramaProps> = ({ url, className }) => {
  // Pannellum needs an ID to mount to so we need to generate a unique one for
  // each instance
  // TODO: Change to useId hook
  const { current: id } = React.useRef(`panorama-${Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000}`)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const viewer = React.useRef<any>(null)

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,functional/immutable-data,@typescript-eslint/no-unsafe-member-access
    viewer.current = pannellum.viewer(id, {
      autoLoad: true,
      yaw: -180,
      panorama: url,
      preview: vehicleDefaultImage,
      dynamicUpdate: true,
      compass: false
    })

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      viewer.current.destroy()
    }
  }, [url, id])

  return <div className={clsx(styles.panoramaContainer, className)} id={id}></div>
}
