import * as React from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { UrlString, VinCode } from '@api/endpoints'
import { carVerticalParamA, carVerticalParamB } from '@constants/configuration'
import SvgVerticalCarLogo from '@assets/icons/logoCarVertical.svg'
import { IconTextLink } from '@controls/buttons'
import styles from './LinkCarVertical.module.css'

const buildCarVerticalUri = (vin: VinCode | undefined): UrlString => {
  if (vin) {
    return `https://www.carvertical.com/precheck?a=${carVerticalParamA}&b=${carVerticalParamB}&vin=${vin}`
  }
  return 'https://www.carvertical.com/'
}

type Props = Readonly<{
  vin?: VinCode
}>

export const LinkCarVertical: React.FC<Props> = ({ vin }) => {
  const { t } = useTranslation()

  return (
    <IconTextLink
      className={clsx(styles.carVerticalLink, 'carVerticalLink')}
      href={buildCarVerticalUri(vin)}
      text={`- ${t('controls.car-vertical.link')}`}
      target="_blank"
    >
      <img src={SvgVerticalCarLogo} alt="CarVertical" />
    </IconTextLink>
  )
}
