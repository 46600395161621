import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { VehicleId } from '@api/endpoints'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { useTranslateValue } from '@hooks/Translation/TranslatedValue'
import { useQueryGetOptionGroups } from '@hooks/ReactQuery/translations/useQueryGetOptionGroups'
import { useQueryGetVehicleOptionsById } from '@hooks/ReactQuery/vehicles/useQueryGetVehicleOptionsById'
import { ButtonAddToCompare } from '@controls/buttons'
import { FlexContainer } from '@layout/FlexContainer'
import styles from './VehicleFeatures.module.css'
import vehiclePageStyles from '../VehiclePage.module.css'

type Props = {
  vehicleId: VehicleId
}

export const VehicleFeatures: React.FC<Props> = ({ vehicleId }) => {
  const [expanded, setExpanded] = React.useState<string | false>(false)
  const { t } = useTranslation()
  const translateValue = useTranslateValue()

  const handleChange = (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const getOptionGroups = useQueryGetOptionGroups()
  const getVehicleOptionsById = useQueryGetVehicleOptionsById(vehicleId)
  const optionTitles = getOptionGroups.data
  const optionBodies = getVehicleOptionsById.data

  const optionGroups = React.useMemo(
    () =>
      optionTitles
        ?.map((objTitle) => ({
          id: objTitle.id,
          title: translateValue(objTitle),
          options: (
            optionBodies?.find((obj) => obj.optionGroup?.value === objTitle.id)?.itemResponses.concat() ?? []
          ).map((v) => ({
            title: translateValue(v)
          }))
        }))
        .filter((obj) => obj.options.length > 0),
    [optionTitles, optionBodies, translateValue]
  )

  const transformText = (text: string): string => `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`

  return (
    <FlexContainer vertical className={styles.vehicleFeaturesContainer}>
      <FlexContainer justifyContentSpaceBetween>
        <h3 className={vehiclePageStyles.subtitle}>{t('pages.vehicle.equipment.title')}</h3>
        <ButtonAddToCompare className={styles.addToCompare} vehicleId={vehicleId} />
      </FlexContainer>
      <FlexContainer vertical noGap className={styles.accordionContainer}>
        {optionGroups?.map((group, index) => (
          <Accordion
            disableGutters={true}
            sx={{
              '&:before': {
                display: 'none' // https://stackoverflow.com/questions/63488140/how-can-i-remove-line-above-the-accordion-of-material-ui
              }
            }}
            key={`accordion-${group.id}`}
            expanded={expanded === `panel${index + 1}`}
            onChange={handleChange(`panel${index + 1}`)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
              {transformText(group.title)}
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                {group.options.map((item, i) => (
                  <li key={`accordion-detail-${i}`}>{item.title}</li>
                ))}
              </ul>
            </AccordionDetails>
          </Accordion>
        ))}
      </FlexContainer>
    </FlexContainer>
  )
}
