import * as React from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { formatEuroInEstonian } from '@model/language'
import { Price, VehicleId } from '@api/endpoints'
import { RouterLink } from '@controls/buttons'
import { useLanguage } from '@hooks/useLanguage'
import { FlexContainer } from '@layout/FlexContainer'
import { urlWithQueryParamVehicleId } from '@pages/utils'
import styles from './VehicleMonthlyPayment.module.css'

type Props = {
  vehicleId: VehicleId | undefined
  amount: Price | undefined
  fullWidth?: boolean
  //className?: string
}

export const VehicleMonthlyPayment: React.FC<Props> = ({ vehicleId, amount, fullWidth }) => {
  const { t } = useTranslation()
  const { routes } = useLanguage()

  const createLink = (route: string): string => (vehicleId ? urlWithQueryParamVehicleId(route, vehicleId) : route)

  return (
    <RouterLink to={createLink(routes.Installment)} className={clsx(styles.link, { [styles.fullWidth]: fullWidth })}>
      <FlexContainer
        fixedDirection
        justifyContentCenter
        alignItemsCenter
        responsiveGap
        className={clsx(styles.vehicleMonthlyPaymentContainer)}
      >
        <span className={styles.text}>{t('pages.vehicle.monthlyPaymentFrom')}</span>
        <span className={styles.sum}>{formatEuroInEstonian(amount ?? 0)}</span>
      </FlexContainer>
    </RouterLink>
  )
}
