/**
 * NOTE panneullum is not a module so it pollutes window object
 * it should only be imported and instantiated once
 */
import 'pannellum/src/css/pannellum.css'
import 'pannellum/src/js/libpannellum'
import 'pannellum/src/js/pannellum'

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access
export const pannellum = (window as any).pannellum
