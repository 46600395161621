import * as React from 'react'
import Modal from '@mui/material/Modal'
import { CommonModalProps } from '@controls/types'
import { ButtonClose } from '@controls/buttons'
import styles from './ModalPlayer.module.css'

export type ModalPlayerProps = React.IframeHTMLAttributes<HTMLIFrameElement> & CommonModalProps

export const ModalPlayer: React.FC<ModalPlayerProps> = ({ onClose, open, ...props }) => (
  <Modal
    sx={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
      }
    }}
    open={open}
    // eslint-disable-next-line functional/prefer-tacit
    onClose={() => onClose()}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <div className={styles.content}>
      <ButtonClose onClick={onClose} />
      <iframe {...props} />
    </div>
  </Modal>
)
