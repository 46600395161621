import * as React from 'react'
import { UseMutationResult, useMutation } from '@tanstack/react-query'
import { hasValue } from '@digital-magic/ts-common-utils'
import { ApiErrorCode, extractApiErrorCode } from '@api/errors'
import { BookingRequest, postBookingRequest } from '@api/endpoints/forms'
import {
  QueryErrorHandler,
  useDefaultQueryErrorHandlerPublic,
  UseMutationPropsWithInvalidPhoneNumberErrorHandler
} from '@hooks/ReactQuery'

export const useMutationRequestBooking = ({
  onSuccess,
  onInvalidPhoneNumber
}: UseMutationPropsWithInvalidPhoneNumberErrorHandler): UseMutationResult<void, unknown, BookingRequest> => {
  const defaultErrorHandler = useDefaultQueryErrorHandlerPublic()
  const mutation = useMutation({
    mutationFn: postBookingRequest
  })

  const errorHandler: QueryErrorHandler = (e) => {
    if (hasValue(onInvalidPhoneNumber) && extractApiErrorCode(e) === ApiErrorCode.enum.INVALID_PHONE_NUMBER) {
      onInvalidPhoneNumber()
    } else {
      defaultErrorHandler(e)
    }
  }

  React.useEffect(() => {
    if (mutation.error) {
      errorHandler(mutation.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.error])

  React.useEffect(() => {
    if (mutation.isSuccess) {
      void onSuccess?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.isSuccess])

  return mutation
}
