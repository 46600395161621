import * as React from 'react'

export type ImgResponsiveProps = Readonly<{
  srcSm: string
  srcMd: string
  srcLg: string
  srcXl: string
  alt: string
}> &
  Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src' | 'srcSet'>

export const ImgResponsive: React.FC<ImgResponsiveProps> = ({ srcSm, srcMd, srcLg, srcXl, alt, ...props }) => {
  return <img srcSet={`${srcSm} 320w, ${srcMd} 680w, ${srcLg} 960w, ${srcXl} 1980w`} src={srcSm} alt={alt} {...props} />
}
